import * as React from 'react';

import { cn } from '../utils/cn';
import { cva, VariantProps } from 'class-variance-authority';

export const inputClassName =
  'flex h-10 w-full rounded-sm border-none bg-purple-500/20 px-3 py-2 text-lg ring-offset-background file:border-0 file:bg-transparent file:text-base file:font-medium file:text-foreground placeholder:text-white/50 disabled:cursor-not-allowed disabled:opacity-50 md:text-base focus-visible:outline-primary focus-visible:outline';

const inputVariants = cva(inputClassName, {
  variants: {
    variant: {
      default: '',
      destructive: 'border-solid border-[1px] border-negative',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

const Input = React.forwardRef<
  HTMLInputElement,
  React.ComponentProps<'input'> & VariantProps<typeof inputVariants>
>(({ className, variant, type, ...props }, ref) => {
  return (
    <input
      type={type}
      className={cn(inputVariants({ variant, className }))}
      ref={ref}
      {...props}
    />
  );
});
Input.displayName = 'Input';

export { Input };
