import React, { ReactNode } from 'react';
import { cn } from '../utils';

export const Divider = ({
  content,
  containerClassName,
  lineClassName,
}: {
  content?: ReactNode;
  lineClassName?: string;
  containerClassName?: string;
}) => {
  return (
    <div className={cn('flex items-center gap-3', containerClassName)}>
      <div className={cn('h-[1px] flex-1 bg-muted', lineClassName)} />
      {content && (
        <>
          {content}
          <div className={cn('h-[1px] flex-1 bg-muted', lineClassName)} />
        </>
      )}
    </div>
  );
};
