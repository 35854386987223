import * as React from 'react';
import { cn } from '../utils';
import { Message } from './message';

type Props = {
  label?: string;
  className?: string;
  errorMessage?: React.ReactNode | null;
  children?: React.ReactNode;
  hintMessage?: string;

  hintClassName?: string;
  errorClassName?: string;
  labelClassName?: string;
};

const messageClassName = 'text-sm px-2 py-1';

export const FormGroup = (props: Props) => {
  const { className, children, label, errorMessage, hintMessage } = props;

  return (
    <div className={cn('flex flex-col gap-1', className)}>
      {label && <label className={cn(props.labelClassName)}>{label}</label>}

      {children}

      {hintMessage && (
        <Message className={cn(messageClassName, props.hintClassName)}>
          {hintMessage}
        </Message>
      )}

      {errorMessage && (
        <Message
          className={cn(messageClassName, props.errorClassName)}
          variant="negative"
        >
          {errorMessage}
        </Message>
      )}
    </div>
  );
};
